import React, { useEffect, useRef } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/ssr';
import { KeycloakInstance } from 'keycloak-js';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import pickBy from 'lodash/pickBy';
import random from 'lodash/random';
import { keyframes } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

import BrandNameCegedim from '../../components/icons/BrandNameCegedim';
import LoginFormContainer from '../../components/metaOrganisms/forms/Auth/containers/LoginFormContainer';
import TlsLoginFormContainer from '../../components/metaOrganisms/forms/Auth/containers/TlsLoginFormContainer';
import MobileLandingPage from '../../components/organisms/MobileLandingPage';
import { useAutoLoginAction, useLoginAction } from '../../src/hooks/actions';

import { DatacyContext } from '@/src/contexts';

type SProps = {
  loginIllustration: string;
};

const heartAnimation = keyframes`
  0% {
    opacity: 1;
    backgroundPosition: 0 0;
  },
  100% {
    opacity: 1;
    backgroundPosition: -2800px 0;
  }
`;

const useStyles = makeStyles<SProps>()((theme: any, { loginIllustration }) => ({
  loginDoublePage: {
    height: '100vh',
    display: 'flex',
    fontFamily: theme.typography.fontFamily,
  },
  leftlogin: {
    ...theme.pages.loginPasswordReset.leftForm,
    '& > div.form': {
      margin: 0,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      gap: theme.spacing(5),
      '& h2': {
        fontSize: theme.size.font['3xl'],
        margin: 0,
        marginBottom: theme.spacing(5),
      },
      '& form': {
        margin: 0,
        justifyContent: 'flex-start',
        '& button[type="submit"]': {
          alignSelf: 'flex-end',
          padding: `${theme.spacing(2)} ${theme.spacing(7)}`,
        },
      },
    },
    '& .wannajoin': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 'auto',
      '& > div': {
        marginTop: theme.spacing(5),
      },
      '& .bold': { fontWeight: 'bold' },
      '& *': {
        fontSize: theme.size.font.md,
        fontFamily: theme.typography.fontFamily,
      },
      '& a, & .btn': {
        '--color': theme.palette.secondary.dark,
        textDecoration: 'none',
        color: 'var(--color)',
        position: 'relative',
        fontWeight: 500,
        zIndex: theme.zIndex.loginPageJoinUs,
        border: '1px solid',
        borderColor: 'var(--color)',
        borderRadius: theme.spacing(5),
        padding: `${theme.spacing(2)} ${theme.spacing(7)}`,
        '&:hover': {
          '--color': theme.palette.blue.dark,
        },
      },
      '& .btn:hover ~ .heart': {
        transition: 'background 1s steps(28)',
        animation: `${heartAnimation} 1s steps(28)`,
        animationFillMode: 'forwards',
      },
      '& .heart': {
        height: 100,
        width: 100,
        background: 'url(/static/images/heart.png) no-repeat',
        backgroundPosition: '0 0',
        position: 'absolute',
        right: 22,
        bottom: -30,
        opacity: 0,
      },
    },
  },
  illustration: {
    background: `url(${loginIllustration})`,
    ...theme.pages.loginPasswordReset.illustration,
  },
}));

type Props = {
  autoLogin: {
    tokenHash?: string;
    hash?: string;
    data?: string;
    tsToken?: string;
  };
};
// 1075
// http://localhost:3000?centerId=5db073747c237500012ff3af&practitionerId=5db725623391e20001511675&tsToken=5fb2452f583cda66922218a6&appointmentId=5fb2844cc519477349b68af6 &apptPatientId=5f6314d0b649870f8fb6d223 &patientPhoneNumber=0635595048 // BADIA YOPMAIL	Raphael ambroise
// http://localhost:3000?centerId=5d95c1ea2f21370001922b3e&practitionerId=5d95f30c94417b0001d95409&tsToken=5fb2452f583cda66922218a6&appointmentId=5fb2844cc519477349b68af6 &apptPatientId=5f6314d0b649870f8fb6d223 &patientPhoneNumber=0635595048  //perine / JOUBERT DE LA MOTTE DE CALVADI

const loginPractitionerIllustrations = [
  '/static/images/agenda-medecin.svg',
  '/static/images/agenda-medecin-V2.svg',
];
const loginTlsIllustration = [
  '/static/images/rdv-patient-V2.svg',
  '/static/images/agenda-medecin.svg', // TODO: prat for now, needs to change tls girl into a boy! ;)
];
const randomNumber = random(0, 1);

const Login = (props: Props) => {
  const { autoLogin } = props;
  const dispatch = useDispatch();
  const isTelesecretary = autoLogin.tsToken;
  const loginIllustration = isTelesecretary
    ? loginTlsIllustration[randomNumber]
    : loginPractitionerIllustrations[randomNumber];
  const { classes, cx } = useStyles({ loginIllustration });
  const { keycloak, initialized } = useKeycloak<KeycloakInstance>();
  const loginAction = useLoginAction();
  const autoLoginAction = useAutoLoginAction();
  const loginActionRef = useRef(loginAction);
  const autoLoginActionRef = useRef(autoLoginAction);
  loginActionRef.current = loginAction;
  autoLoginActionRef.current = autoLoginAction;
  const authError = useSelector(state => state.authentication?.error);
  const isSsoLogin = initialized && keycloak?.authenticated && keycloak?.token;
  const isAutoLogin = !isEmpty(autoLogin);
  const hasSsoFailed = authError?.action?.chunkResource === 'uaa';
  const uaaToken = keycloak?.token;

  useEffect(() => {
    if (isAutoLogin && hasSsoFailed) {
      dispatch(autoLoginActionRef.current(autoLogin));
    }
  }, [hasSsoFailed, isAutoLogin, dispatch, autoLogin]);

  useEffect(() => {
    if (isSsoLogin) {
      dispatch(loginActionRef.current({ uaaToken }));
    } else if (isAutoLogin) {
      dispatch(autoLoginActionRef.current(autoLogin));
    }
  }, [autoLogin, dispatch, isAutoLogin, isSsoLogin, uaaToken]);

  const ComponentForm = isTelesecretary
    ? TlsLoginFormContainer
    : LoginFormContainer;

  return (
    <DatacyContext.Provider value="login_page">
      {isMobileOnly ? (
        // Redirect to app pro mobile if user is on smartphone
        <MobileLandingPage />
      ) : (
        <div className={classes.loginDoublePage}>
          <div className={cx(classes.leftlogin, isTelesecretary && 'tls')}>
            <div className="form">
              <BrandNameCegedim colored datacy="brand_logo" className="logo" />
              <ComponentForm autoLogin={autoLogin} />
            </div>
          </div>
          <div className={classes.illustration} />
        </div>
      )}
    </DatacyContext.Provider>
  );
};

Login.getInitialProps = async ({
  query: { tokenHash, data, hash, tsToken },
  res,
}) => {
  const autoLogin = pickBy(
    { tokenHash, data, hash, tsToken },
    value => !isNil(value),
  );
  if (tokenHash) {
    /**
     * Remove refreshPro, tokenPro from server side
     */
    res.setHeader('Set-Cookie', [
      `tokenPro=deleted; Max-Age=0`,
      `refreshPro=deleted; Max-Age=0`,
    ]);
  }
  return { autoLogin, namespacesRequired: ['common'] };
};

export default Login;
