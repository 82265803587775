import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useLoginAction } from '../../../../../../src/hooks/actions';
import TlsLoginFormView from '../../views/TlsLoginFormView';
import { TlsNameFormFields } from '../TlsNameFormContainer/TlsNameFormContainer';

import { DatacyContext } from '@/src/contexts/DatacyContext';
import { useDatacyName } from '@/src/hooks/datacy';

type Props = {
  autoLogin: {
    tokenHash?: string;
    hash?: string;
    data?: string;
    tsToken?: string;
  };
};

const TlsLoginFormContainer = ({ autoLogin }: Props) => {
  const dispatch = useDispatch();
  const loginAction = useLoginAction();
  const datacyName = useDatacyName('tls');
  const [tlsName, setTlsName] = useState<TlsNameFormFields | null>(null);

  const onSubmit = (securityCode: string) => {
    if (tlsName) {
      const { tlsFirstName, tlsLastName } = tlsName;
      const loginValues =
        tlsFirstName || tlsLastName
          ? { ...autoLogin, securityCode, tlsFirstName, tlsLastName }
          : { ...autoLogin, securityCode };
      dispatch(loginAction(loginValues));
    }
  };

  return (
    <DatacyContext.Provider value={datacyName}>
      <TlsLoginFormView
        isTlsNameForm={!tlsName}
        onSubmitTlsName={setTlsName}
        onSubmitTlsCode={onSubmit}
      />
    </DatacyContext.Provider>
  );
};

export default TlsLoginFormContainer;
