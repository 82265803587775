import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { useTranslation } from '../../../../../../i18n';
import { authenticationActions } from '../../../../../../src/actions';
import { useResetRessourceError } from '../../../../../../src/hooks/actions';

import DigitInput from '@/components/atoms/DigitInput';
import { InputGroup } from '@/src/components/InputGroup';
import { DatacyContext } from '@/src/contexts/DatacyContext';
import { useDatacyName } from '@/src/hooks/datacy';

const useStyles = makeStyles()((theme: any) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6),
  },
  error: {
    color: theme.palette.destructive.main,
    textAlign: 'center',
  },
}));

type Props = {
  onSubmit: (fieldValues: string) => void;
};

const ConfirmCodeFormContainer = (props: Props) => {
  const { t } = useTranslation('common');
  const { classes } = useStyles();
  const { onSubmit } = props;
  const datacyName = useDatacyName('confirm_code');
  useResetRessourceError(authenticationActions, []);
  const error = useSelector(state => state.authentication?.error);
  const [value, setValue] = React.useState(['', '', '', '']);
  const name = 'fields.';

  const handleInput: React.CompositionEventHandler<HTMLInputElement> = event => {
    const field = event.currentTarget;
    const index = parseInt(field.name.replace(name, ''), 10);
    const fieldValue = field.value;
    setValue(prevValue =>
      prevValue.map((v, i) => (i === index ? fieldValue : v)),
    );
  };
  const userTemporarilyBlacklisted = 'USER_TEMPORARILY_BLACKLISTED';
  const userPermanentlyBlacklisted = 'USER_PERMANENTLY_BLACKLISTED';

  return (
    <DatacyContext.Provider value={datacyName}>
      <form
        className={classes.form}
        onSubmit={event => {
          event.preventDefault();
          onSubmit(value.join(''));
        }}
      >
        <InputGroup value={value} onInput={handleInput} name={name}>
          <DigitInput autoFocus type="password" />
          <DigitInput type="password" />
          <DigitInput type="password" />
          <DigitInput type="password" />
        </InputGroup>
        {error?.code === userTemporarilyBlacklisted && (
          <Typography className={classes.error}>
            {t('login_confirm_code_error_blacklisted')}
          </Typography>
        )}
        {error?.code === userPermanentlyBlacklisted && (
          <Typography className={classes.error}>
            {t('login_confirm_code_error_blacklisted_definitively')}
          </Typography>
        )}
        {error &&
          error?.code !== userTemporarilyBlacklisted &&
          error?.code !== userPermanentlyBlacklisted && (
            <Typography className={classes.error}>
              {t('login_confirm_code_error')}
            </Typography>
          )}
        <Button
          sx={{ width: '100%' }}
          variant="contained"
          color="primary"
          type="submit"
        >
          {t('auth_card__button_login')}
        </Button>
      </form>
    </DatacyContext.Provider>
  );
};

export default ConfirmCodeFormContainer;

// http://localhost:3000/login?tsToken=5ee0d988c610ae3f77cdad5c 1168
