import React, { forwardRef } from 'react';
import {
  FormHelperText as MaterialFormHelperText,
  FormHelperTextProps,
} from '@mui/material';

import { useDatacyName } from '@/src/hooks/datacy';

type Props = FormHelperTextProps;

const FormHelperText = forwardRef(
  (props: Props, ref: React.Ref<HTMLInputElement>): JSX.Element => {
    const { className, datacy } = props;
    const datacyName = useDatacyName(datacy);
    return (
      <MaterialFormHelperText
        classes={{ root: className }}
        {...props}
        datacy={datacyName}
        ref={ref}
      />
    );
  },
);

FormHelperText.displayName = 'FormHelperText';

export default FormHelperText;
