import React, { ReactNode } from 'react';
import { Formik, FormikValues } from 'formik';

import { DatacyContext } from '@/src/contexts/DatacyContext';
import { useDatacyName } from '@/src/hooks/datacy';

type Props<Values extends FormikValues> = {
  children: ReactNode;
  formRef: any;
  id?: string;
  initialValues: Values;
  name?: string;
  onSubmit?: (value: Values, form: { setSubmitting: any }) => void;
  validationSchema?: any | (() => any); // matches the type definition of Formik.validationSchema
  validate?: (values: Values) => void;
  validateOnChange?: boolean;
};

const FormikBuilder = <Values extends FormikValues>(props: Props<Values>) => {
  const {
    children,
    formRef,
    id,
    initialValues = {} as Values,
    onSubmit = () => {},
    validationSchema = null,
    name = 'form',
    ...rest
  } = props;

  const datacyName = useDatacyName(name);

  return (
    <DatacyContext.Provider value={datacyName}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        innerRef={formRef}
        {...rest}
      >
        {ctx => (typeof children === 'function' ? children(ctx) : children)}
      </Formik>
    </DatacyContext.Provider>
  );
};

export default FormikBuilder;
