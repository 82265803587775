import React from 'react';
import { Formik } from 'formik';
import capitalize from 'lodash/capitalize';
import toLower from 'lodash/toLower';
import upperFirst from 'lodash/upperFirst';

import TlsNameSchema from '../../../../../FormikSchema/TlsNameSchema';
import TlsNameFormView from '../../views/TlsNameFormView';

import { DatacyContext } from '@/src/contexts/DatacyContext';
import { useDatacyName } from '@/src/hooks/datacy';

export type TlsNameFormFields = { tlsFirstName: string; tlsLastName: string };

type Props = {
  onSubmit: (values: TlsNameFormFields | null) => void;
};

const TlsNameFormContainer = (props: Props) => {
  const { onSubmit } = props;
  const datacyName = useDatacyName('name_form');

  const onFormSubmit = (
    values: TlsNameFormFields,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    const { tlsFirstName, tlsLastName } = values;
    onSubmit({
      tlsFirstName: upperFirst(toLower(tlsFirstName)),
      tlsLastName: capitalize(tlsLastName),
    });
    setSubmitting(false);
  };

  const initialValues = { tlsFirstName: '', tlsLastName: '' };

  return (
    <DatacyContext.Provider value={datacyName}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) =>
          onFormSubmit(values, setSubmitting)
        }
        onReset={(_, { setSubmitting }) =>
          onFormSubmit(initialValues, setSubmitting)
        }
        validationSchema={TlsNameSchema}
      >
        {form => <TlsNameFormView form={form} />}
      </Formik>
    </DatacyContext.Provider>
  );
};

export default TlsNameFormContainer;
